<template>
  <v-card>
    <v-snackbar color="red" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card-title class="grid-close">
      <span class="headline">Reporte de usuarios</span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        v-if="!loading"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-select
                rounded
                filled
                item-text="text"
                label="Seleccionar tipo de usuario"
                item-value="value"
                v-model="selectedType"
                persistent-hint
                :rules="[rules.required]"
                :items="userTypes"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-btn
                rounded
                :loading="loading"
                @click="generateReport"
                height="55"
                color="primary"
                block
              >
                Generar reporte
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>
    
    <script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import axios from "axios";

export default {
  name: "orders-by-user",
  props: ["type"],
  components: {},
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      selectedType: "",
      loading: false,
      userTypes: [
        {
          text: "Agente Facilitador",
          value: "AF",
        },
        {
          text: "Agente Contable",
          value: "ACC",
        },
        {
          text: "Agente Comercial",
          value: "AC",
        },
        {
          text: "Comercios Ocho Pay",
          value: "oplBusiness",
        },
        {
          text: "Escáner Ocho Vive",
          value: "viveScanner",
        },

        {
          text: "Ocho Cargo",
          value: "cargo",
        },
        {
          text: "Sidekick",
          value: "sidekick",
        },

        {
          text: "Socios comerciales",
          value: "businessOwner",
        },
      ],
      rules: {
        required: (value) => !!value.length || "Obligatorio",
      },
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  methods: {
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },

    async generateReport() {
      if (!this.$refs.form.validate()) {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        return;
      }

      this.loading = true;

      let token = await this.getSectionToken();

      let payload = {
        type: this.selectedType,
      };

      axios
        .post(
            `${process.env.VUE_APP_FUNCTIONS_URL}/httpReportsUsers`,
        //   "http://127.0.0.1:5001/el-ocho-dev/us-central1/httpReportsUsers",
          payload,
          {
            headers: { Authorization: `Basic ${token}` },
            responseType: "blob",
          }
        )
        .then((response) => {
          this.loading = false;
          this.$emit("cancel");

          const { data, headers } = response;
          const fileName = `Reporte de usuarios ${this.selectedType}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },

  async mounted() {
    this.selectedType = this.type;
  },
};
</script>
    